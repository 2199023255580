<template>
  <v-container fluid fill-height>
    <v-layout flex align-center justify-center>
      <v-flex xs12 sm8 md12>
        <v-row justify="center">
          <v-col align="center">
            <img src="../assets/SPICE_Logo.png" alt="" class="img-hover" />
          </v-col>
        </v-row>
        <v-row justify="center">
          <div v-for="link in links">
            <v-col align="center">
              <VButton
                :to="link.to"
                :title="link.title"
                :text="link.text"
                :subtitle="link.subtitle"
                :hoverText="link.hoverText"
                :img="link.img"
              />
            </v-col>
          </div>
        </v-row>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import VButton from "@/components/VButton.vue";

export default {
  name: "Home",
  components: { VButton },
  data: () => {
    return {
      links: [
        {
          title: "Capture",
          text: "Digitalisieren",
          subtext: "alsjknans",
          to: "https://spice-dev.ics-cloud.services/spicecapture",
          img:
            "https://images.unsplash.com/photo-1605918321412-d6504db4748e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80",
          hoverText: "Spice Capture öffnen",
        },
        {
          title: "Process Editor",
          text: "Bearbeiten",
          subtext: "alsjknans",
          to: "https://spice-dev.ics-cloud.services/ProcessEditor",
          img:
            "https://images.unsplash.com/photo-1581431886288-e216ea4ccf3f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1050&q=80",
          hoverText: "Editor öffnen",
        },
        {
          title: "Process Monitor",
          text: "Überwachen",
          subtext: "alsjknans",
          to: "https://spice-dev.ics-cloud.services/ProcessMonitor",
          img:
            "https://images.pexels.com/photos/355948/pexels-photo-355948.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
          hoverText: "Monitor öffnen",
        },
        {
          title: "Online Learning",
          text: "Anlernen",
          subtext: "alsjknans",
          to: "https://spice-dev.ics-cloud.services/OLExtraction/#/dashboard/",
          img:
            "https://images.pexels.com/photos/3184644/pexels-photo-3184644.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
          hoverText: "Laera öffnen",
        },
        {
          title: "Field Review",
          text: "Validieren",
          subtext: "alsjknans",
          to: "https://spice-dev.ics-cloud.services/FieldReview/",
          img:
            "https://media.istockphoto.com/photos/auditor-makes-internal-audit-of-company-and-checks-financial-report-picture-id1216178370",
          hoverText: "Review öffnen",
        },
        // {
        // 	title: "System Monitor",
        // 	text: "Digitalisieren Sie ihre Dokumente",
        // 	subtext: "alsjknans",
        // 	to: "https://spice-dev.ics-cloud.services/SystemMonitor",
        // 	img: "https://images.pexels.com/photos/669619/pexels-photo-669619.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
        // 	hoverText: "System Logs öffnen",
        // },
      ],
    };
  },
};
</script>

<style scoped>
.img-hover {
  width: 400px;
  padding: 2em;
}
</style>
