<template>
	<v-app>
		<v-app-bar app color="primary" dark>
			<v-spacer></v-spacer>
			<v-toolbar-title>Willkommen bei <b>Spice 365</b></v-toolbar-title>
			<v-spacer></v-spacer>
		</v-app-bar>

		<v-main>
			<v-container>
				<router-view />
			</v-container>
		</v-main>
	</v-app>
</template>

<script>
export default {
	name: "App",
};
</script>
