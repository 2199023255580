<template>
	<div>
		<v-hover v-slot="{ hover }">
			<v-card
				class="mx-auto"
				color="grey lighten-4"
				width="230"
				height="300"
				:href="to"
			>
				<v-img :aspect-ratio="16 / 9" :src="img">
					<v-expand-transition>
						<div
							v-if="hover"
							class="
								d-flex
								transition-fast-in-fast-out
								primary
								darken-2
								v-card--reveal
								text-h4
								white--text
							"
							style="height: 100%"
						>
							{{ hoverText }}
						</div>
					</v-expand-transition>
				</v-img>
				<v-card-text class="pt-6" style="position: relative">
					<v-btn
						absolute
						color="primary"
						class="white--text"
						fab
						large
						right
						top
					>
						<v-icon>mdi-arrow-right</v-icon>
					</v-btn>
					<div class="font-weight-light grey--text text-h6 mb-2">
						{{ text }}
					</div>
					<h3 class="text-h4 font-weight-light primary--text mb-2">
						{{ title }}
					</h3>
					<div class="font-weight-light text-h6 mb-2">
						{{ subtitle }}
					</div>
				</v-card-text>
			</v-card>
		</v-hover>
	</div>
</template>

<script>
export default {
	name: "Link",
	props: ["title", "subtitle", "text", "to", "img", "hoverText"],
	data: () => {
		return {
			hover: false,
		};
	},
	methods: {
		navigate() {
			console.log(this.link);
		},
	},
};
</script>

<style>
.v-card--reveal {
	align-items: center;
	bottom: 0;
	justify-content: center;
	opacity: 0.9;
	position: absolute;
	width: 100%;
}
</style>